function _typeof(t) {
    return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
        return typeof t
    } : function (t) {
        return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t
    }, _typeof(t)
}

function css_browser_selector(t) {
    var e = t.toLowerCase(), i = function (t) {
            return e.indexOf(t) > -1
        }, n = "gecko", r = "webkit", o = "safari", s = "opera", a = "mobile", l = document.documentElement,
        u = [!/opera|webtv/i.test(e) && /msie\s(\d)/.test(e) ? "ie ie" + RegExp.$1 : i("firefox/2") ? n + " ff2" : i("firefox/3.5") ? n + " ff3 ff3_5" : i("firefox/3.6") ? n + " ff3 ff3_6" : i("firefox/3") ? n + " ff3" : i("gecko/") ? n : i("opera") ? s + (/version\/(\d+)/.test(e) ? " " + s + RegExp.$1 : /opera(\s|\/)(\d+)/.test(e) ? " " + s + RegExp.$2 : "") : i("konqueror") ? "konqueror" : i("blackberry") ? a + " blackberry" : i("android") ? a + " android" : i("chrome") ? r + " chrome" : i("iron") ? r + " iron" : i("applewebkit/") ? r + " " + o + (/version\/(\d+)/.test(e) ? " " + o + RegExp.$1 : "") : i("mozilla/") ? n : "", i("j2me") ? a + " j2me" : i("iphone") ? a + " iphone" : i("ipod") ? a + " ipod" : i("ipad") ? a + " ipad" : i("mac") || i("darwin") ? "mac" : i("webtv") ? "webtv" : i("win") ? "win" + (i("windows nt 6.0") ? " vista" : "") : i("freebsd") ? "freebsd" : i("x11") || i("linux") ? "linux" : "", "js"];
    return c = u.join(" "), l.className += " " + c, c
}

css_browser_selector(navigator.userAgent);
/*! npm.im/object-fit-images 3.2.4 */
var objectFitImages = function () {
    "use strict";

    function t(t, e, i) {
        var n = function (t, e) {
            return "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='" + t + "' height='" + e + "'%3E%3C/svg%3E"
        }(e || 1, i || 0);
        p.call(t, "src") !== n && m.call(t, "src", n)
    }

    function e(t, i) {
        t.naturalWidth ? i(t) : setTimeout(e, 100, t, i)
    }

    function i(i) {
        var r   = function (t) {
            for (var e, i = getComputedStyle(t).fontFamily, n = {}; null !== (e = s.exec(i));) n[e[1]] = e[2];
            return n
        }(i), a = i[o];
        if (r["object-fit"] = r["object-fit"] || "fill", !a.img) {
            if ("fill" === r["object-fit"]) return;
            if (!a.skipTest && l && !r["object-position"]) return
        }
        if (!a.img) {
            a.img = new Image(i.width, i.height), a.img.srcset = p.call(i, "data-ofi-srcset") || i.srcset, a.img.src = p.call(i, "data-ofi-src") || i.src, m.call(i, "data-ofi-src", i.src), i.srcset && m.call(i, "data-ofi-srcset", i.srcset), t(i, i.naturalWidth || i.width, i.naturalHeight || i.height), i.srcset && (i.srcset = "");
            try {
                n(i)
            } catch (i) {
                window.console && console.warn("https://bit.ly/ofi-old-browser")
            }
        }
        (function (t) {
            if (t.srcset && !f && window.picturefill) {
                var e = window.picturefill._;
                t[e.ns] && t[e.ns].evaled || e.fillImg(t, {reselect: !0}), t[e.ns].curSrc || (t[e.ns].supported = !1, e.fillImg(t, {reselect: !0})), t.currentSrc = t[e.ns].curSrc || t.src
            }
        })(a.img), i.style.backgroundImage = 'url("' + (a.img.currentSrc || a.img.src).replace(/"/g, '\\"') + '")', i.style.backgroundPosition = r["object-position"] || "center", i.style.backgroundRepeat = "no-repeat", i.style.backgroundOrigin = "content-box", /scale-down/.test(r["object-fit"]) ? e(a.img, (function () {
            a.img.naturalWidth > i.width || a.img.naturalHeight > i.height ? i.style.backgroundSize = "contain" : i.style.backgroundSize = "auto"
        })) : i.style.backgroundSize = r["object-fit"].replace("none", "auto").replace("fill", "100% 100%"), e(a.img, (function (e) {
            t(i, e.naturalWidth, e.naturalHeight)
        }))
    }

    function n(t) {
        var e = {
            get   : function (e) {
                return t[o].img[e || "src"]
            }, set: function (e, n) {
                return t[o].img[n || "src"] = e, m.call(t, "data-ofi-" + n, e), i(t), e
            }
        };
        Object.defineProperty(t, "src", e), Object.defineProperty(t, "currentSrc", {
            get: function () {
                return e.get("currentSrc")
            }
        }), Object.defineProperty(t, "srcset", {
            get   : function () {
                return e.get("srcset")
            }, set: function (t) {
                return e.set(t, "srcset")
            }
        })
    }

    function r(t, e) {
        var n = !h && !t;
        if (e = e || {}, t = t || "img", c && !e.skipTest || !u) return !1;
        "img" === t ? t = document.getElementsByTagName("img") : "string" == typeof t ? t = document.querySelectorAll(t) : "length" in t || (t = [t]);
        for (var s = 0; s < t.length; s++) t[s][o] = t[s][o] || {skipTest: e.skipTest}, i(t[s]);
        n && (document.body.addEventListener("load", (function (t) {
            "IMG" === t.target.tagName && r(t.target, {skipTest: e.skipTest})
        }), !0), h = !0, t = "img"), e.watchMQ && window.addEventListener("resize", r.bind(null, t, {skipTest: e.skipTest}))
    }

    var o = "bfred-it:object-fit-images", s = /(object-fit|object-position)\s*:\s*([-.\w\s%]+)/g,
        a = "undefined" == typeof Image ? {style: {"object-position": 1}} : new Image, l = "object-fit" in a.style,
        c = "object-position" in a.style, u = "background-size" in a.style, f = "string" == typeof a.currentSrc,
        p = a.getAttribute, m = a.setAttribute, h = !1;
    return r.supportsObjectFit = l, r.supportsObjectPosition = c, function () {
        function t(t, e) {
            return t[o] && t[o].img && ("src" === e || "srcset" === e) ? t[o].img : t
        }

        c || (HTMLImageElement.prototype.getAttribute = function (e) {
            return p.call(t(this, e), e)
        }, HTMLImageElement.prototype.setAttribute = function (e, i) {
            return m.call(t(this, e), e, String(i))
        })
    }(), r
}();

// function $yubin(t) {
//     AjaxZip3.callback(t)
// }

// AjaxZip3 = function () {
// }, AjaxZip3.VERSION = "0.51", AjaxZip3.JSONDATA = "https://yubinbango.github.io/yubinbango-data/data", AjaxZip3.CACHE = [], AjaxZip3.prev = "", AjaxZip3.nzip = "", AjaxZip3.fzip1 = "", AjaxZip3.fzip2 = "", AjaxZip3.fpref = "", AjaxZip3.addr = "", AjaxZip3.fstrt = "", AjaxZip3.farea = "", AjaxZip3.ffocus = !0, AjaxZip3.onSuccess = null, AjaxZip3.onFailure = null, AjaxZip3.PREFMAP = [null, "北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県", "茨城県", "栃木県", "群馬県", "埼玉県", "千葉県", "東京都", "神奈川県", "新潟県", "富山県", "石川県", "福井県", "山梨県", "長野県", "岐阜県", "静岡県", "愛知県", "三重県", "滋賀県", "京都府", "大阪府", "兵庫県", "奈良県", "和歌山県", "鳥取県", "島根県", "岡山県", "広島県", "山口県", "徳島県", "香川県", "愛媛県", "高知県", "福岡県", "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県"], AjaxZip3.zip2addr = function (t, e, i, n, r, o, s) {
//     if (AjaxZip3.fzip1 = AjaxZip3.getElementByName(t), AjaxZip3.fzip2 = AjaxZip3.getElementByName(e, AjaxZip3.fzip1), AjaxZip3.fpref = AjaxZip3.getElementByName(i, AjaxZip3.fzip1), AjaxZip3.faddr = AjaxZip3.getElementByName(n, AjaxZip3.fzip1), AjaxZip3.fstrt = AjaxZip3.getElementByName(o, AjaxZip3.fzip1), AjaxZip3.farea = AjaxZip3.getElementByName(r, AjaxZip3.fzip1), AjaxZip3.ffocus = void 0 === s || s, AjaxZip3.fzip1 && AjaxZip3.fpref && AjaxZip3.faddr) {
//         var a = AjaxZip3.fzip1.value;
//         if (AjaxZip3.fzip2 && AjaxZip3.fzip2.value && (a += AjaxZip3.fzip2.value), a) {
//             AjaxZip3.nzip = "";
//             for (var l = 0; l < a.length; l++) {
//                 var c = a.charCodeAt(l);
//                 c < 48 || (c > 57 || (AjaxZip3.nzip += a.charAt(l)))
//             }
//             if (!(AjaxZip3.nzip.length < 7)) {
//                 var u = AjaxZip3.nzip.substr(0, 3), f = AjaxZip3.CACHE[u];
//                 if (f) return AjaxZip3.callback(f);
//                 AjaxZip3.zipjsonpquery()
//             }
//         }
//     }
// }, AjaxZip3.callback = function (t) {
//     function e() {
//         "function" == typeof AjaxZip3.onFailure && AjaxZip3.onFailure()
//     }

//     var i = t[AjaxZip3.nzip], n = AjaxZip3.nzip - 0 + 4278190080 + "";
//     if (!i && t[n] && (i = t[n]), i) {
//         var r = i[0];
//         if (r) {
//             var o = AjaxZip3.PREFMAP[r];
//             if (o) {
//                 var s = i[1];
//                 s || (s = "");
//                 var a = i[2];
//                 a || (a = "");
//                 var l = i[3];
//                 l || (l = "");
//                 var c = AjaxZip3.faddr, u = s;
//                 if ("select-one" == AjaxZip3.fpref.type || "select-multiple" == AjaxZip3.fpref.type) for (var f = AjaxZip3.fpref.options, p = 0; p < f.length; p++) {
//                     var m         = f[p].value, h = f[p].text;
//                     f[p].selected = m == r || m == o || h == o
//                 } else AjaxZip3.fpref.name == AjaxZip3.faddr.name ? u = o + u : AjaxZip3.fpref.value = o;
//                 if (AjaxZip3.farea ? (c = AjaxZip3.farea, AjaxZip3.farea.value = a) : u += a, AjaxZip3.fstrt && (c = AjaxZip3.fstrt, AjaxZip3.faddr.name == AjaxZip3.fstrt.name ? u += l : l && (AjaxZip3.fstrt.value = l)), AjaxZip3.faddr.value = u, "function" == typeof AjaxZip3.onSuccess && AjaxZip3.onSuccess(), AjaxZip3.ffocus && c && c.value) {
//                     var d = c.value.length;
//                     if (c.focus(), c.createTextRange) {
//                         var g = c.createTextRange();
//                         g.move("character", d), g.select()
//                     } else c.setSelectionRange && c.setSelectionRange(d, d)
//                 }
//             } else e()
//         } else e()
//     } else e()
// }, AjaxZip3.getResponseText = function (t) {
//     var e = t.responseText;
//     if (navigator.appVersion.indexOf("KHTML") > -1) {
//         var i = escape(e);
//         i.indexOf("%u") < 0 && i.indexOf("%") > -1 && (e = decodeURIComponent(i))
//     }
//     return e
// }, AjaxZip3.getElementByName = function (t, e) {
//     if ("string" == typeof t) {
//         var i = document.getElementsByName(t);
//         if (!i) return null;
//         if (!(i.length > 1 && e && e.form)) return i[0];
//         for (var n = e.form.elements, r = 0; r < n.length; r++) if (n[r].name == t) return n[r]
//     }
//     return t
// }, AjaxZip3.zipjsonpquery = function () {
//     var t = AjaxZip3.JSONDATA + "/" + AjaxZip3.nzip.substr(0, 3) + ".js", e = document.createElement("script");
//     e.setAttribute("type", "text/javascript"), e.setAttribute("charset", "UTF-8"), e.setAttribute("src", t), document.getElementsByTagName("head").item(0).appendChild(e)
// }, function (t, e) {
//     "object" == ("undefined" == typeof exports ? "undefined" : _typeof(exports)) && "object" == ("undefined" == typeof module ? "undefined" : _typeof(module)) ? module.exports = e() : "function" == typeof define && define.amd ? define("simpleParallax", [], e) : "object" == ("undefined" == typeof exports ? "undefined" : _typeof(exports)) ? exports.simpleParallax = e() : t.simpleParallax = e()
// }(window, (function () {
//     return function (t) {
//         var e = {};

//         function i(n) {
//             if (e[n]) return e[n].exports;
//             var r = e[n] = {i: n, l: !1, exports: {}};
//             return t[n].call(r.exports, r, r.exports, i), r.l = !0, r.exports
//         }

//         return i.m = t, i.c = e, i.d = function (t, e, n) {
//             i.o(t, e) || Object.defineProperty(t, e, {enumerable: !0, get: n})
//         }, i.r = function (t) {
//             "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(t, Symbol.toStringTag, {value: "Module"}), Object.defineProperty(t, "__esModule", {value: !0})
//         }, i.t = function (t, e) {
//             if (1 & e && (t = i(t)), 8 & e) return t;
//             if (4 & e && "object" == _typeof(t) && t && t.__esModule) return t;
//             var n = Object.create(null);
//             if (i.r(n), Object.defineProperty(n, "default", {
//                 enumerable: !0,
//                 value     : t
//             }), 2 & e && "string" != typeof t) for (var r in t) i.d(n, r, function (e) {
//                 return t[e]
//             }.bind(null, r));
//             return n
//         }, i.n = function (t) {
//             var e = t && t.__esModule ? function () {
//                 return t.default
//             } : function () {
//                 return t
//             };
//             return i.d(e, "a", e), e
//         }, i.o = function (t, e) {
//             return Object.prototype.hasOwnProperty.call(t, e)
//         }, i.p = "", i(i.s = 0)
//     }([function (t, e, i) {
//         "use strict";
//         i.r(e), i.d(e, "default", (function () {
//             return v
//         }));

//         function n(t, e) {
//             for (var i = 0; i < e.length; i++) {
//                 var n = e[i];
//                 n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(t, n.key, n)
//             }
//         }

//         var r   = new (function () {
//             function t() {
//                 !function (t, e) {
//                     if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function")
//                 }(this, t), this.positions = {top: 0, bottom: 0, height: 0}
//             }

//             var e, i;
//             return e = t, (i = [{
//                 key: "setViewportTop", value: function (t) {
//                     return this.positions.top = t ? t.scrollTop : window.pageYOffset, this.positions
//                 }
//             }, {
//                 key: "setViewportBottom", value: function () {
//                     return this.positions.bottom = this.positions.top + this.positions.height, this.positions
//                 }
//             }, {
//                 key: "setViewportAll", value: function (t) {
//                     return this.positions.top = t ? t.scrollTop : window.pageYOffset, this.positions.height = t ? t.clientHeight : document.documentElement.clientHeight, this.positions.bottom = this.positions.top + this.positions.height, this.positions
//                 }
//             }]) && n(e.prototype, i), t
//         }()), o = function (t) {
//             return NodeList.prototype.isPrototypeOf(t) || HTMLCollection.prototype.isPrototypeOf(t) ? Array.from(t) : "string" == typeof t || t instanceof String ? document.querySelectorAll(t) : [t]
//         }, s    = function () {
//             for (var t, e = "transform webkitTransform mozTransform oTransform msTransform".split(" "), i = 0; void 0 === t;) t = void 0 !== document.createElement("div").style[e[i]] ? e[i] : void 0, i += 1;
//             return t
//         }();

//         function a(t, e) {
//             (null == e || e > t.length) && (e = t.length);
//             for (var i = 0, n = new Array(e); i < e; i++) n[i] = t[i];
//             return n
//         }

//         function l(t, e) {
//             for (var i = 0; i < e.length; i++) {
//                 var n = e[i];
//                 n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(t, n.key, n)
//             }
//         }

//         var c = function () {
//             function t(e, i) {
//                 var n = this;
//                 !function (t, e) {
//                     if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function")
//                 }(this, t), this.element = e, this.elementContainer = e, this.settings = i, this.isVisible = !0, this.isInit = !1, this.oldTranslateValue = -1, this.init = this.init.bind(this), this.customWrapper = this.settings.customWrapper && this.element.closest(this.settings.customWrapper) ? this.element.closest(this.settings.customWrapper) : null, function (t) {
//                     return "img" !== t.tagName.toLowerCase() && "picture" !== t.tagName.toLowerCase() || !!t && !!t.complete && (void 0 === t.naturalWidth || 0 !== t.naturalWidth)
//                 }(e) ? this.init() : this.element.addEventListener("load", (function () {
//                     setTimeout((function () {
//                         n.init(!0)
//                     }), 50)
//                 }))
//             }

//             var e, i;
//             return e = t, (i = [{
//                 key: "init", value: function (t) {
//                     var e = this;
//                     this.isInit || (t && (this.rangeMax = null), this.element.closest(".simpleParallax") || (!1 === this.settings.overflow && this.wrapElement(this.element), this.setTransformCSS(), this.getElementOffset(), this.intersectionObserver(), this.getTranslateValue(), this.animate(), this.settings.delay > 0 ? setTimeout((function () {
//                         e.setTransitionCSS(), e.elementContainer.classList.add("simple-parallax-initialized")
//                     }), 10) : this.elementContainer.classList.add("simple-parallax-initialized"), this.isInit = !0))
//                 }
//             }, {
//                 key: "wrapElement", value: function () {
//                     var t = this.element.closest("picture") || this.element,
//                         e = this.customWrapper || document.createElement("div");
//                     e.classList.add("simpleParallax"), e.style.overflow = "hidden", this.customWrapper || (t.parentNode.insertBefore(e, t), e.appendChild(t)), this.elementContainer = e
//                 }
//             }, {
//                 key: "unWrapElement", value: function () {
//                     var t = this.elementContainer;
//                     this.customWrapper ? (t.classList.remove("simpleParallax"), t.style.overflow = "") : t.replaceWith.apply(t, function (t) {
//                         return function (t) {
//                             if (Array.isArray(t)) return a(t)
//                         }(t) || function (t) {
//                             if ("undefined" != typeof Symbol && Symbol.iterator in Object(t)) return Array.from(t)
//                         }(t) || function (t, e) {
//                             if (t) {
//                                 if ("string" == typeof t) return a(t, e);
//                                 var i = Object.prototype.toString.call(t).slice(8, -1);
//                                 return "Object" === i && t.constructor && (i = t.constructor.name), "Map" === i || "Set" === i ? Array.from(t) : "Arguments" === i || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(i) ? a(t, e) : void 0
//                             }
//                         }(t) || function () {
//                             throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.")
//                         }()
//                     }(t.childNodes))
//                 }
//             }, {
//                 key: "setTransformCSS", value: function () {
//                     !1 === this.settings.overflow && (this.element.style[s] = "scale(".concat(this.settings.scale, ")")), this.element.style.willChange = "transform"
//                 }
//             }, {
//                 key: "setTransitionCSS", value: function () {
//                     this.element.style.transition = "transform ".concat(this.settings.delay, "s ").concat(this.settings.transition)
//                 }
//             }, {
//                 key: "unSetStyle", value: function () {
//                     this.element.style.willChange = "", this.element.style[s] = "", this.element.style.transition = ""
//                 }
//             }, {
//                 key: "getElementOffset", value: function () {
//                     var t = this.elementContainer.getBoundingClientRect();
//                     if (this.elementHeight = t.height, this.elementTop = t.top + r.positions.top, this.settings.customContainer) {
//                         var e           = this.settings.customContainer.getBoundingClientRect();
//                         this.elementTop = t.top - e.top + r.positions.top
//                     }
//                     this.elementBottom = this.elementHeight + this.elementTop
//                 }
//             }, {
//                 key: "buildThresholdList", value: function () {
//                     for (var t = [], e = 1; e <= this.elementHeight; e++) {
//                         var i = e / this.elementHeight;
//                         t.push(i)
//                     }
//                     return t
//                 }
//             }, {
//                 key: "intersectionObserver", value: function () {
//                     var t = {root: null, threshold: this.buildThresholdList()};
//                     this.observer = new IntersectionObserver(this.intersectionObserverCallback.bind(this), t), this.observer.observe(this.element)
//                 }
//             }, {
//                 key: "intersectionObserverCallback", value: function (t) {
//                     var e = this;
//                     t.forEach((function (t) {
//                         t.isIntersecting ? e.isVisible = !0 : e.isVisible = !1
//                     }))
//                 }
//             }, {
//                 key: "checkIfVisible", value: function () {
//                     return this.elementBottom > r.positions.top && this.elementTop < r.positions.bottom
//                 }
//             }, {
//                 key: "getRangeMax", value: function () {
//                     var t         = this.element.clientHeight;
//                     this.rangeMax = t * this.settings.scale - t
//                 }
//             }, {
//                 key: "getTranslateValue", value: function () {
//                     var t = ((r.positions.bottom - this.elementTop) / ((r.positions.height + this.elementHeight) / 100)).toFixed(1);
//                     return t = Math.min(100, Math.max(0, t)), 0 !== this.settings.maxTransition && t > this.settings.maxTransition && (t = this.settings.maxTransition), this.oldPercentage !== t && (this.rangeMax || this.getRangeMax(), this.translateValue = (t / 100 * this.rangeMax - this.rangeMax / 2).toFixed(0), this.oldTranslateValue !== this.translateValue && (this.oldPercentage = t, this.oldTranslateValue = this.translateValue, !0))
//                 }
//             }, {
//                 key: "animate", value: function () {
//                     var t, e = 0, i = 0;
//                     (this.settings.orientation.includes("left") || this.settings.orientation.includes("right")) && (i = "".concat(this.settings.orientation.includes("left") ? -1 * this.translateValue : this.translateValue, "px")), (this.settings.orientation.includes("up") || this.settings.orientation.includes("down")) && (e = "".concat(this.settings.orientation.includes("up") ? -1 * this.translateValue : this.translateValue, "px")), t = !1 === this.settings.overflow ? "translate3d(".concat(i, ", ").concat(e, ", 0) scale(").concat(this.settings.scale, ")") : "translate3d(".concat(i, ", ").concat(e, ", 0)"), this.element.style[s] = t
//                 }
//             }]) && l(e.prototype, i), t
//         }();

//         function u(t) {
//             return function (t) {
//                 if (Array.isArray(t)) return p(t)
//             }(t) || function (t) {
//                 if ("undefined" != typeof Symbol && Symbol.iterator in Object(t)) return Array.from(t)
//             }(t) || f(t) || function () {
//                 throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.")
//             }()
//         }

//         function f(t, e) {
//             if (t) {
//                 if ("string" == typeof t) return p(t, e);
//                 var i = Object.prototype.toString.call(t).slice(8, -1);
//                 return "Object" === i && t.constructor && (i = t.constructor.name), "Map" === i || "Set" === i ? Array.from(t) : "Arguments" === i || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(i) ? p(t, e) : void 0
//             }
//         }

//         function p(t, e) {
//             (null == e || e > t.length) && (e = t.length);
//             for (var i = 0, n = new Array(e); i < e; i++) n[i] = t[i];
//             return n
//         }

//         function m(t, e) {
//             for (var i = 0; i < e.length; i++) {
//                 var n = e[i];
//                 n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(t, n.key, n)
//             }
//         }

//         var h, d, g = !1, y = [], v = function () {
//             function t(e, i) {
//                 if (function (t, e) {
//                     if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function")
//                 }(this, t), e && Element.prototype.closest && "IntersectionObserver" in window) {
//                     if (this.elements = o(e), this.defaults = {
//                         delay          : 0,
//                         orientation    : "up",
//                         scale          : 1.3,
//                         overflow       : !1,
//                         transition     : "cubic-bezier(0,0,0,1)",
//                         customContainer: "",
//                         customWrapper  : "",
//                         maxTransition  : 0
//                     }, this.settings = Object.assign(this.defaults, i), this.settings.customContainer) {
//                         var n                = function (t, e) {
//                             return function (t) {
//                                 if (Array.isArray(t)) return t
//                             }(t) || function (t, e) {
//                                 if ("undefined" != typeof Symbol && Symbol.iterator in Object(t)) {
//                                     var i = [], n = !0, r = !1, o = void 0;
//                                     try {
//                                         for (var s, a = t[Symbol.iterator](); !(n = (s = a.next()).done) && (i.push(s.value), !e || i.length !== e); n = !0) ;
//                                     } catch (t) {
//                                         r = !0, o = t
//                                     } finally {
//                                         try {
//                                             n || null == a.return || a.return()
//                                         } finally {
//                                             if (r) throw o
//                                         }
//                                     }
//                                     return i
//                                 }
//                             }(t, e) || f(t, e) || function () {
//                                 throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.")
//                             }()
//                         }(o(this.settings.customContainer), 1);
//                         this.customContainer = n[0]
//                     }
//                     this.lastPosition = -1, this.resizeIsDone = this.resizeIsDone.bind(this), this.refresh = this.refresh.bind(this), this.proceedRequestAnimationFrame = this.proceedRequestAnimationFrame.bind(this), this.init()
//                 }
//             }

//             var e, i;
//             return e = t, (i = [{
//                 key: "init", value: function () {
//                     var t = this;
//                     r.setViewportAll(this.customContainer), y = [].concat(u(this.elements.map((function (e) {
//                         return new c(e, t.settings)
//                     }))), u(y)), g || (this.proceedRequestAnimationFrame(), window.addEventListener("resize", this.resizeIsDone), g = !0)
//                 }
//             }, {
//                 key: "resizeIsDone", value: function () {
//                     clearTimeout(d), d = setTimeout(this.refresh, 200)
//                 }
//             }, {
//                 key: "proceedRequestAnimationFrame", value: function () {
//                     var t = this;
//                     r.setViewportTop(this.customContainer), this.lastPosition !== r.positions.top ? (r.setViewportBottom(), y.forEach((function (e) {
//                         t.proceedElement(e)
//                     })), h = window.requestAnimationFrame(this.proceedRequestAnimationFrame), this.lastPosition = r.positions.top) : h = window.requestAnimationFrame(this.proceedRequestAnimationFrame)
//                 }
//             }, {
//                 key: "proceedElement", value: function (t) {
//                     (this.customContainer ? t.checkIfVisible() : t.isVisible) && t.getTranslateValue() && t.animate()
//                 }
//             }, {
//                 key: "refresh", value: function () {
//                     r.setViewportAll(this.customContainer), y.forEach((function (t) {
//                         t.getElementOffset(), t.getRangeMax()
//                     })), this.lastPosition = -1
//                 }
//             }, {
//                 key: "destroy", value: function () {
//                     var t = this, e = [];
//                     y = y.filter((function (i) {
//                         return t.elements.includes(i.element) ? (e.push(i), !1) : i
//                     })), e.forEach((function (e) {
//                         e.unSetStyle(), !1 === t.settings.overflow && e.unWrapElement()
//                     })), y.length || (window.cancelAnimationFrame(h), window.removeEventListener("resize", this.refresh), g = !1)
//                 }
//             }]) && m(e.prototype, i), t
//         }()
//     }]).default
// }));